import { defineStore } from 'pinia'

const INITIAL_STATE = {
  partners: []
}

export const partnersStore = defineStore('partnersStore', {
  state: () => ({
    partners: { ...INITIAL_STATE },
  }),
  persist: {
    storage: sessionStorage,
    paths: ['partners']
  },
  actions: {
    async setPartnerList(partnerList) {
      this.partners = {
        partners: partnerList
      }
    }
  }
})
