import { config } from '../../data/config'

function parseSettings() {
  let sentryDsn = import.meta.env.VITE_SENTRY_DSN_PRODUCTION
  let location = config.api.production.url

  if (window.location.hostname.match('client.staging.ashwellness.io')) {
    location = config.api.staging.url
    sentryDsn = import.meta.env.VITE_SENTRY_DSN_STAGING
  }

  if (window.location.hostname.match('client.dev.ashwellness.io')) {
    location = config.api.development.url
    sentryDsn = import.meta.env.VITE_SENTRY_DSN_DEV
  }

  if (window.location.hostname === 'localhost') {
    location = config.api.local.url
    sentryDsn = import.meta.env.VITE_SENTRY_DSN_DEV
  }

  localStorage.setItem('location', location)
  sessionStorage.setItem('sentry', JSON.stringify(sentryDsn))

  return { sentryDsn, location }
}

function init() {
  let sentryDsn = JSON.parse(sessionStorage.getItem('sentry'))
  let location = localStorage.getItem('location')

  if (
    sentryDsn === null ||
    sentryDsn === undefined ||
    location === null ||
    location === undefined ||
    location.indexOf('pp-api') === -1
  ) {
    const settings = parseSettings()
    sentryDsn = settings.sentryDsn
  }

  return {
    sentryDsn,
    location
  }
}

export default { init }
