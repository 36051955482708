import './styles/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import VueApexCharts from 'vue3-apexcharts'
import { VueCookie } from 'vue-cookies'
import integrations from './services/integrations/integrations.js'
import sentryConfig from './services/integrations/sentry.js'
import Toast from 'vue-toastification'

import App from './App.vue'
import router from './router'

import 'vue-toastification/dist/index.css'

const app = createApp(App)

let integration = integrations.init()
if (integration?.sentryDsn) {
  sentryConfig.init(app, integration.sentryDsn, router)
  console.log('Sentry successfully initialized...')
}

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)

app.use(pinia)
app.use(VueCookie)

app.use(router)
app.use(VueApexCharts)
app.use(Toast, {
  newestOnTop: true,
  draggable: false,
  transition: 'Vue-Toastification__fade',
  timeout: 3000
})

app.mount('#app')
