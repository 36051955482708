import { defineStore } from 'pinia'

const INITIAL_STATE = {
  accessToken: '',
  selectedClientId: '',
  userInfo: null
}

export const userStore = defineStore('userStore', {
  state: () => ({
    user: { ...INITIAL_STATE }
  }),
  persist: {
    storage: sessionStorage,
    paths: ['user']
  },
  actions: {
    setUser(user) {
      this.user = { ...user }
      sessionStorage.setItem('token', user.accessToken)
      sessionStorage.setItem('user_info', JSON.stringify(user))
      sessionStorage.setItem('expires_at', user.minExpDate)
      sessionStorage.setItem('selected_client_id', user.selectedClientId)
    },
    clearUser() {
      this.user = { ...INITIAL_STATE }
    }
  }
})
