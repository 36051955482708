import { axiosClient } from '../api/axios-client'
import VueCookies from 'vue-cookies'
import logger from '../utils/logger'
import { add, isPast } from 'date-fns'
import { DEMOPARTNER_NAME, ASH_NAME } from '../constants/clients'
import orderBy from 'lodash/orderBy'

function clearSessionStorage() {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('expires_at')
  sessionStorage.removeItem('user_info')
  sessionStorage.removeItem('selected_client_id')
  sessionStorage.removeItem('ordersStore')
  sessionStorage.removeItem('partnersStore')
  sessionStorage.removeItem('stories')
  sessionStorage.removeItem('userStore')
  sessionStorage.removeItem('settingsStore')
}

export async function getPartnerList(access_token) {
  let partners = []
  // Get partner list
  const partnersResponse = await axiosClient.get('v2/clients/users/me/partners', {
    headers: {
      'x-access-token': access_token
    }
  })

  if (partnersResponse.data.partners.length > 0) {
    partners = partnersResponse.data.partners.map((partner) => {
      return {
        partner_id: partner.partner_id,
        partner_name: partner.partner_name
      }
    })

    partners = orderBy(partners, ['partner_name'], ['asc'])
  }

  return partners
}

export function getPartnerId(partnerId) {
  return partnerId === ASH_NAME ? DEMOPARTNER_NAME : partnerId
}

export function setInactivityLogoutTime() {
  return add(new Date(), { minutes: 15 })
}

export function checkUserToken() {
  const expAt = sessionStorage.getItem('expires_at')
  const dateTimeOfExpiration = new Date(expAt)

  if (isPast(dateTimeOfExpiration)) {
    return false
  }

  const currentToken = VueCookies.get('token')

  // Check if cookie has expired
  if (!currentToken) {
    return false
  }

  const newExpDate = setInactivityLogoutTime()

  VueCookies.set('token', currentToken, { expires: newExpDate, path: '/' })
  sessionStorage.setItem('expires_at', newExpDate)
  return true
}

export async function clearUserAuth() {
  const currentToken = VueCookies.get('token')
  if (!currentToken) {
    return false
  }
  const url = 'v2/auth/logout'
  const body = {
    token: currentToken
  }

  try {
    await axiosClient.post(url, body)
    VueCookies.remove('token')
    localStorage.removeItem('encodedToken')
    clearSessionStorage()

    return true
  } catch (error) {
    VueCookies.remove('token')
    clearSessionStorage()
    logger.error('An error occurred while performing this request!', error)
    return false
  }
}
