const ENVIRONMENT = import.meta.env.VITE_BASE_URL

const logger = {
  log(message, logInProduction = false, ...args) {
    if (ENVIRONMENT !== 'production' || logInProduction) {
      console.log(message, ...args)
    }
  },
  warn(message, logInProduction = false, ...args) {
    if (ENVIRONMENT !== 'production' || logInProduction) {
      console.warn(message, ...args)
    }
  },
  error(message, ...args) {
    console.error(`[${message}]`, ...args)
  }
}

export default logger
