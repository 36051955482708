export const CLIENT_NAMES = [
  {
    clientId: 'ash',
    name: 'Ash Wellness'
  },
  {
    clientId: 'fulton',
    name: 'Fulton County'
  },
  {
    clientId: 'ocfh',
    name: 'Orange County'
  }
]

export const DEMOPARTNER_NAME = 'demopartner'
export const ASH_NAME = 'ash'

export const NO_DEFAULT_USER_ACCOUNT_API_MSG = 'No partner_id provided and user does not have a default.'

export const NO_DEFAULT_USER_ACCOUNT_MSG_ERROR = 'Email and/or password is incorrect. Please review credentials.'

export const USER_ALREADY_EXISTS_IN_AUTH_API_MSG_ERROR = 'User already exists in Auth API.'

export const USER_ALREADY_INVITED_MSG_ERROR = 'This user has already been invited.'

export const EXISTING_USER_ACCOUNT_MSG_ERROR = 'An account with this email address is already associated with this selected client, please use a different one or contact support.'