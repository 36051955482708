<template>
  <div v-if="msgType === 'success'" class="tw-w-auto tw-min-16 tw-font-gilroy">
    <div>
      <div v-if="msgTitle">{{ msgTitle }}</div>
      <div>
        {{ body }}
      </div>
    </div>
  </div>
  <div v-else-if="msgType === 'error'" class="tw-w-auto tw-min-16 tw-font-gilroy">
    <div class="tw-flex tw-gap-2 tw-flex-col">
      <div>{{ msgTitle ? msgTitle : 'Error Occurred' }}</div>
      <div>
        {{ body }}
      </div>
    </div>
  </div>
  <div v-else-if="msgType === 'info'" class="tw-w-auto tw-min-16 tw-font-gilroy">
    <div>
      <div v-if="msgTitle">{{ msgTitle }}</div>
      <div>
        {{ body }}
      </div>
    </div>
  </div>
  <div v-else class="tw-w-auto tw-min-16 tw-font-gilroy">
    <div>
      <div v-if="msgTitle">{{ msgTitle }}</div>
      <div>
        {{ body }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToastMessages',
  props: {
    msgType: {
      type: String,
      required: true
    },
    msgTitle: {
      type: String,
      required: false
    },
    body: {
      type: String,
      required: true
    }
  }
}
</script>
