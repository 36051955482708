export const config = {
  api: {
    local: {
      url: 'https://pp-api.dev.ashwellness.io'
    },
    development: {
      url: 'https://pp-api.dev.ashwellness.io'
    },
    staging: {
      url: 'https://pp-api.staging.ashwellness.io'
    },
    production: {
      url: 'https://pp-api.ashwellness.io'
    }
  }
}
