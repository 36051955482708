import { useToast } from 'vue-toastification'
import ToastMessages from '../components/ToastMessages.vue'

export function showSuccessToast(message, msgTitle) {
  const toast = useToast()
  toast.success(
    {
      component: ToastMessages,
      props: {
        msgTitle,
        msgType: 'success',
        body: message
      }
    },
    {
      toastClassName: 'toast-custom-success'
    }
  )
}

export function showErrorToast(message, msgTitle) {
  const toast = useToast()
  toast.error(
    {
      component: ToastMessages,
      props: {
        msgTitle,
        msgType: 'error',
        body: message
      }
    },
    {
      toastClassName: 'toast-custom-error'
    }
  )
}

export function showWarningToast(message, msgTitle) {
  const toast = useToast()
  toast.warning(
    {
      component: ToastMessages,
      props: {
        msgTitle,
        msgType: 'warning',
        body: message
      }
    },
    {
      toastClassName: 'toast-custom-warning'
    }
  )
}

export function showInfoToast(message) {
  const toast = useToast()
  toast.info(
    {
      component: ToastMessages,
      props: {
        msgType: 'info',
        body: message
      }
    },
    {
      toastClassName: 'toast-custom-info'
    }
  )
}
